<template>
    <div>
        <page-title>{{ $t('menu.push_notifications') }}</page-title>

        <form-panel v-for="(subTypes, group) in types" :key="group">
            <h3 class="mb-4 text-capitalize">
                {{ $t('send_push_notifications.box_title', { group }) }}
            </h3>
            <v-select
                v-model="selected[group].type"
                hide-details
                :label="$t('send_push_notifications.type')"
                :items="subTypes"
                item-text="type"
            ></v-select>
            <div v-if="selected[group].type" class="pt-8">
                <div>{{ $t('send_push_notifications.parameters') }}</div>
                <div class="ml-4 mb-4">
                    <v-text-field
                        v-for="parameter in getSelectedParameters(group)"
                        :key="parameter"
                        v-model="selected[group].parameters[parameter]"
                        hide-details
                        :label="parameter"
                    ></v-text-field>
                </div>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn @click="send(group)">{{ $t('actions.send') }}</v-btn>
                </div>
            </div>
        </form-panel>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import FormPanel from '@/components/form/FormPanel.vue'
import NotificationsApiClient from '@/api/RestApi/NotificationsApiClient'
import Notification from '@/services/Notification/Notification'

export default {
    components: {
        FormPanel,
        PageTitle,
    },
    data() {
        return {
            activeTab: 0,
            selected: {
                restaurant: {
                    type: null,
                    parameters: {},
                },
                user: {
                    type: null,
                    parameters: {},
                },
            },
            pages: ['restaurant_admin.orders', 'canteens', 'orders'],
            types: {
                restaurant: [
                    {
                        type: 'new-order',
                        parameters: ['orderId'],
                    },
                    {
                        type: 'order-approved',
                        parameters: ['orderId'],
                    },
                    {
                        type: 'order-cancelled',
                        parameters: ['orderId'],
                    },
                ],
                user: [
                    {
                        type: 'send-to-logged-in-user',
                        parameters: [],
                    },
                    {
                        type: 'send-to-user',
                        parameters: ['userId'],
                    },
                    {
                        type: 'residential-canteen-reminder',
                        parameters: ['residentialCanteenId', 'restaurantId'],
                    },
                    {
                        type: 'residential-canteen-available',
                        parameters: ['residentialCanteenId'],
                    },
                    {
                        type: 'cloud-canteen-reminder',
                        parameters: ['cloudCanteenId', 'restaurantId'],
                    },
                    {
                        type: 'cloud-canteen-available',
                        parameters: ['cloudCanteenId'],
                    },
                    {
                        type: 'personal-pickup-ready',
                        parameters: ['orderId'],
                    },
                    {
                        type: 'qr-delivery-finished',
                        parameters: ['orderId'],
                    },
                    {
                        type: 'delivering',
                        parameters: ['orderId'],
                    },
                    {
                        type: 'almost-delivering',
                        parameters: ['orderId'],
                    },
                    {
                        type: 'finished',
                        parameters: ['orderId'],
                    },
                    {
                        type: 'cancelled',
                        parameters: ['orderId'],
                    },
                ],
            },
        }
    },
    computed: {},
    mounted() {},
    methods: {
        async send(group) {
            let result

            if (group === 'user') {
                switch (this.selected.user.type) {
                    case 'send-to-logged-in-user':
                        result = await NotificationsApiClient.testDirectToCurrentUser(
                            this.selected.user.parameters.userId
                        )
                        break
                    case 'send-to-user':
                        result = await NotificationsApiClient.testDirectToUser(
                            this.selected.user.parameters.userId
                        )
                        break
                    default:
                        result = await NotificationsApiClient.testUser(
                            this.selected.user.type,
                            this.selected.user.parameters
                        )
                }
            } else if (group === 'restaurant') {
                result = await NotificationsApiClient.testRestaurant(
                    this.selected.restaurant.type,
                    this.selected.restaurant.parameters
                )
            }

            if (!result.sent) {
                Notification.requestError(
                    this.$t('send_push_notifications.send.error')
                )
            } else {
                Notification.success(
                    this.$t('send_push_notifications.send.success')
                )
            }
        },
        getSelectedParameters(group) {
            return this.types[group].find(
                (x) => x.type === this.selected[group].type
            )?.parameters
        },
    },
}
</script>
